import nanoajax from 'nanoajax'

class Cart {
  get () {
    return new Promise(resolve => {
      nanoajax.ajax({
        url: '/cart.js',
        method: 'get'
      }, (code, responseText, request) => {
        const cart = JSON.parse(responseText)
        resolve(cart)
      })
    })
  }

  updateNote (note) {
    return new Promise((resolve, reject) => {
      nanoajax.ajax({
        url: '/cart/update.js',
        method: 'post',
        body: `note=${note}`
      }, (code, responseText, request) => {
        const cart = JSON.parse(responseText)
        if (Number(code) !== 200) {
          cart['errors'] = [cart.description]
        }
        resolve(cart)
      })
    })
  }

  generateShopifyCartChangeBody ({ id, quantity }) {
    return `quantity=${quantity}&id=${id}`
  }

  change (data) {
    return new Promise((resolve, reject) => {
      nanoajax.ajax({
        url: '/cart/change.js',
        method: 'post',
        body: this.generateShopifyCartChangeBody(data)
      }, (code, responseText, request) => {
        const cart = JSON.parse(responseText)
        if (Number(code) !== 200) {
          cart['errors'] = [cart.description]
        }
        resolve(cart)
      })
    })
  }

  generateShopifyCartUpdateBody (data) {
    return data instanceof Array
      ? data.map(({id, quantity}) => `updates[${id}]=${quantity}`).join('&')
      : `updates[${data.id}]=${data.quantity}`
  }

  update (data) {
    return new Promise((resolve, reject) => {
      nanoajax.ajax({
        url: '/cart/update.js',
        method: 'post',
        body: this.generateShopifyCartUpdateBody(data)
      }, (code, responseText, request) => {
        const cart = JSON.parse(responseText)
        if (Number(code) !== 200) {
          cart['errors'] = [cart.description]
        }
        resolve(cart)
      })
    })
  }

  /**
   * Serialize request body
   *
   * @param {Array} variants [{id: <id>, quantity: <Number>}]
   * @returns {string} HTTP Query
   */
  generateShopifyCartAddBody (variants) {
    return variants.reduce((query, variant, index) => {
      const {id, quantity, sellingPlanId, properties} = variant

      query += `&items[${index}][quantity]=${quantity}&items[${index}][id]=${id}`
      if (sellingPlanId) {
        query += `&items[${index}][selling_plan]=${sellingPlanId}`
      }
      if (!properties || !Object.keys(properties).length) {
        return query
      }

      return Object.keys(properties).reduce((query, key) => {
        query += `&items[${index}][properties][${key}]=${properties[key]}`

        return query
      }, query)
    }, '')
  }

  /**
   * Adds one or multiple variants to the cart
   *
   * @param {Array} variants [{id: <id>, quantity: <Number>}]
   * @returns {Promise} Resolves with the line items object
   */
  add (variants) {
    return new Promise(resolve => {
      nanoajax.ajax({
        url: '/cart/add.js',
        method: 'post',
        body: this.generateShopifyCartAddBody(variants)
      }, (code, responseText, request) => {
        const cart = JSON.parse(responseText)
        resolve(cart)
      })
    })
  }
}

export default new Cart()
